import { validateAddressSuggestionTemplate } from './templates';
import { AddressType, AddressTypeTranslation } from './valid';

const successStatus: number[] = [11];
const partSuccessStatus: number[] = [12, 14, 15];
const errorStatus: number[] = [13, 16];
const poboxTypes: number[] = [3, 4, 5, 7];

export class AddressValidator {
  private buttonId: string;
  private target: string;
  private $feedback: JQuery<HTMLElement>;
  private $suggestions: JQuery<HTMLElement>;
  private $addressType: JQuery<HTMLElement>;
  private $addressTypeInput: JQuery<HTMLElement>;

  public static init(buttonId: string, target: string): AddressValidator {
    const validator = new AddressValidator(buttonId, target);
    validator.init();

    return validator;
  }

  constructor(buttonId: string, target: string) {
    this.buttonId = buttonId;
    this.target = target;
  }

  init(): void {
    this.$feedback = $("#validation-results");
    this.$suggestions = $("#validation-suggestions");
    this.$addressType = $("#Address1Type");
    this.$addressTypeInput = $("#ResponseAddressType");

    $(this.buttonId).click(e => this.validate(e));
    $(".row").on('click', '.address-suggestion', suggestion => this.selectSuggestion(suggestion));
  }

  private validate(event: JQuery.ClickEvent) {
    const $btn = $(event.target);

    $btn
      .attr("disabled", "disabled")
      .addClass('disabled')
      .data('initialValue', $btn.html())
      .html($btn.data('loading-text'));

    const data = $btn.parents("form").serialize();

    $.ajax({
      url: this.target,
      method: 'post',
      data: data
    }).done(res => {
      $btn.removeClass('disabled')
        .removeAttr('disabled')
        .html($btn.data('initialValue'));

      if (res.hasError) {
        this.$feedback.removeClass('text-success text-warning').addClass('text-danger');
        this.$feedback.html(res.error.status_text);
        this.$suggestions.html('');

        return;
      }

      const success = res.response.is_valid === 1;

      this.$feedback.html(res.response.status_text);
      if (success || successStatus.indexOf(res.response.status) > -1) {
        this.$feedback.removeClass('text-danger text-warning').addClass('text-success');
        this.setValidated();
      } else if (partSuccessStatus.indexOf(res.response.status) > -1) {
        this.$feedback.removeClass('text-danger text-success').addClass('text-warning');
      } else {
        this.$feedback.removeClass('text-success text-warning').addClass('text-danger');
      }

      if (res.response.status !== 12 && res.response.suggestions != null && res.response.suggestions.length > 0) {
        this.$suggestions.html(validateAddressSuggestionTemplate(res.response));
      } else {
        this.$suggestions.html('');
      }

      if (res.response.status === 12 && res.response.suggestions != null && res.response.suggestions.length === 1) {
        // Auto-corrected to one!
        const correct = res.response.suggestions[0];
        this.setValidated();
        this.setAddressType(correct.address_type);
        $("#Address1").val(`${correct.street} ${correct.street_number || ''}${correct.letter || ''}`);
        $("#ZipCode").val(correct.postalcode);
        $("#City").val(correct.locality);
      }

      if (res.response.match != null && res.response.match.address_type != undefined) {
        this.setAddressType(res.response.match.address_type);
      }
    }).fail(err => {
      $btn.removeClass('disabled')
        .removeAttr('disabled')
        .html($btn.data('initialValue'));

      this.$feedback.removeClass('text-success').addClass('text-danger')
        .html(`${this.$feedback.data('error')}: ${err.status} (${err.statusText})`);
      console.error(err);
    });

    event.preventDefault();
    return false;
  }

  private selectSuggestion(event: JQuery.ClickEvent): boolean {
    const $target = $(event.currentTarget);

    this.setValidated();
    $("#Address1").val(`${$target.data('street')} ${$target.data('street-number') || ''}${$target.data('letter') || ''}`);
    $("#ZipCode").val($target.data('postalcode'));
    $("#City").val($target.data('locality'));

    event.preventDefault();
    return false;
  }

  private setValidated(): void {
    $("#LastValidatedUtc").val(new Date().toISOString());
  }

  private setAddressType(type: number): void {
    this.$addressType.html(AddressTypeTranslation[AddressType[type]]);
    this.$addressTypeInput.val(AddressType[type]);

    if (poboxTypes.indexOf(type) > -1) {
      this.$addressType.addClass('text-danger');
      $("#submit").removeClass('btn-primary').addClass('btn-warning');
    } else {
      this.$addressType.removeClass('text-danger');
      $("#submit").addClass('btn-primary').removeClass('btn-warning');
    }
  }
}
