import * as jQuery from 'jquery';
import { checkDayFn, checkWorkstationFn } from './filter-functions';

declare var Tryckhuset: any;

jQuery.fn.dataTable.ext.afnFiltering.push(function (oSettings: DataTables.SettingsLegacy, aData: string[], iDataIndex: number) {
    var $table = $(oSettings.nTable);
    var filter = (oSettings.nTable as HTMLTableElement).dataset.filter;
    if (filter && filter.indexOf('deadline') > -1) {
        // Find day checkboxes
        var mondayCheck = $table.parents('.tab-pane').find('input[name=monday]');
        var tuesdayCheck = $table.parents('.tab-pane').find('input[name=tuesday]');
        var wednesdayCheck = $table.parents('.tab-pane').find('input[name=wednesday]');
        var thursdayCheck = $table.parents('.tab-pane').find('input[name=thursday]');
        var fridayCheck = $table.parents('.tab-pane').find('input[name=friday]');
        var saturdayCheck = $table.parents('.tab-pane').find('input[name=saturday]');
        var sundayCheck = $table.parents('.tab-pane').find('input[name=sunday]');

        // Convert to days
        var activeDays: number[] = [];
        if (mondayCheck.is(':checked')) activeDays.push(1);
        if (tuesdayCheck.is(':checked')) activeDays.push(2);
        if (wednesdayCheck.is(':checked')) activeDays.push(3);
        if (thursdayCheck.is(':checked')) activeDays.push(4);
        if (fridayCheck.is(':checked')) activeDays.push(5);
        if (saturdayCheck.is(':checked')) activeDays.push(6);
        if (sundayCheck.is(':checked')) activeDays.push(7);

        // Find day on order
        return checkDayFn(oSettings, iDataIndex, aData, activeDays) && checkWorkstationFn(oSettings, iDataIndex, false);
    }

    return true;
});