export function drawCallback(settings: DataTables.Settings): void {
  const api = new $.fn.dataTable.Api(settings as any);
  let prevOrder = '';

  $.each(api.rows({ search: 'applied' }).nodes(), (index: number, el: any) => {
    var row = el as HTMLTableRowElement;
    var order = row.dataset['order'];
    if (order === prevOrder) {
      $(row).find("[data-hide='except-first']").hide();
    } else {
      $(row).find("[data-hide='except-first']").show();
    }

    prevOrder = order;
  });
}

export function sumCallback(row: HTMLElement, _data: any[], _start: number, _end: number, _display: any[]): void {
  var api = (this as any).api();

  var intVal = function (i: string | number) {
    if (typeof i === 'string')
      if (i === '')
        return 0;
      else
        return parseInt(i.replace(/[\(\)%,]/g, ''), 10);

    if (typeof i === 'number')
      return i || 0;
  };

  var colIndex: number;
  var processed: number;
  var total: number;
  var percent: number;

  var $row = $(row);
  $row.find("[data-progress]").each((i, el) => {
    var colIndex = $row.find("th").index(el);
    processed = api
      .column(colIndex, { page: 'current' })
      .data()
      .reduce(function (a, b) {
        const html = $($.parseHTML(b));
        let text = '';
        if (html.hasClass("progress-summary"))
          text = html.text().trim().split('/')[0];

        return intVal(a) + intVal(text);
      }, 0);

    total = api
      .column(colIndex, { page: 'current' })
      .data()
      .reduce(function (a, b) {
        const html = $($.parseHTML(b));
        let text = '';
        if (html.hasClass("progress-summary"))
          text = html.text().trim();

        if (text !== '' && text.indexOf('/'))
          text = text.split('/')[1];

        return intVal(a) + intVal(text);
      }, 0);

    if (total == 0)
      percent = 0;
    else
      percent = Math.round(processed / total * 100);

    $(api.column(colIndex).footer()).html(
      `${processed.toString(10)} / ${total.toString(10)} (${percent}%)`
    );
  });
}