const $ = require('jquery');
import BlunderClient from 'blunder-js';
import * as qrcode from 'qrcode-generator';

import { TryckhusetWindow } from './window';

import { Config } from "./config";
import { Deadline } from "./deadline";
import { Fortnox } from "./fortnox";
import { Filtering } from "./filtering";
import { IncomingDeliveries } from "./incoming-deliveries";
import { Notifications } from "./notifications";
import { Operator } from "./operator";
import { OperatorSummary } from "./operator-summary";
import { OutgoingDeliveries } from "./outgoing-deliveries";
import { PdfViewer } from "./pdf-viewer";
import { Preparation } from "./preparation";
import { PreparationRows } from "./preparation-rows";
import { Routings } from "./routings";
import { Uploader } from "./uploader";
import { Statistics } from "./statistics";
import { getQuerystringParam } from "./querystring";
import { User } from "./admin";
import { AddressValidator } from './address-validator';

declare var window: TryckhusetWindow;

const Auth = {
  QRCode: qrcode
};

const Admin = {
  User: User
};

const localConfig = new Config();

const App = {
  init() {
    Tryckhuset.Config.setupDefaults();
    Tryckhuset.Blunder = new BlunderClient(Tryckhuset.Config.blunder);
    if (Tryckhuset.Config.blunder.user)
      Tryckhuset.Blunder.setUserName(Tryckhuset.Config.blunder.user);
    $('[rel=select2]').select2();
    $('[rel=datatable]').DataTable();
    $('[rel=datepicker]').datepicker();
    $('.clockpicker').clockpicker();
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();
    window.LocalTime.config.locale = 'sv';
    window.LocalTime.start();

    Notifications.init();

    $("[data-toggle=popover-html]").popover({
      html: true,
      content: function () {
        return $((this as HTMLElement).dataset['src']).html();
      },
    });

    $("fieldset[rel='collapsible'] legend").click(function () {
      $(this)
        .siblings(".collapsible-container").slideToggle()
        .parents("[rel='collapsible']").toggleClass('collapsed');
    });
  },

  autoRefresh(interval: number = 5 * 60 * 1000) {
    setTimeout(function () {
      if (Tryckhuset.Config.doReload) {
        window.location.reload(true);
      }
    }, interval);
  }
}

const Tryckhuset = {
  Blunder: null,
  Config: new Config(),
  CurrentUserId: "",
};

export {
  App, Auth, Admin, localConfig as Config, Deadline, Fortnox, Filtering, IncomingDeliveries, Operator, OperatorSummary,
  OutgoingDeliveries, PdfViewer, Preparation, PreparationRows, Routings, Uploader,
  Statistics, getQuerystringParam as querystring, AddressValidator
};
