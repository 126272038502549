import { TranslationLookupDict } from "./utils";

export enum AddressType {
  // Location address - General
  NA,
  // Location address - City
  NB,
  // Location address - Rural
  NC,
  // PO Box address - General
  BA,
  // PO Box address - Main operator
  BB,
  // PO Box address - Other operator
  BC,
  // Large customer address - Organisation with their own postal code
  BD,
  // PO Box address - Package dispenser
  BE,
  // Administrative address - General
  AA,
  // Administrative address - Internal address
  AB,
  // Administrative address - Competition mail(tävlingspost)
  AC,
  // Administrative address - Reply mail(svarspost / frisvar / svarsförsändelser)
  AD,
  // Any other address that has not been classified
  XX,
}

export const AddressTypeTranslation: TranslationLookupDict = {
  'NA': 'Location address - General',
  'NB': 'Location address - City',
  'NC': 'Location address - Rural',
  'BA': 'PO Box address - General',
  'BB': 'PO Box address - Main operator',
  'BC': 'PO Box address - Other operator',
  'BD': 'Large customer address - Organisation with their own postal code',
  'BE': 'PO Box address - Package dispenser',
  'AA': 'Administrative address - General',
  'AB': 'Administrative address - Internal address',
  'AC': 'Administrative address - Competition mail (tävlingspost)',
  'AD': 'Administrative address - Reply mail (svarspost / frisvar / svarsförsändelser)',
  'XX': 'Any other address that has not been classified',
}
