var $ = require('jquery');
import Sortable from 'sortablejs';

export class Routings {
  private idSuffix = '';
  private selected: HTMLElement = null;
  private available: HTMLElement = null;
  private orderId: HTMLInputElement = null;
  private selectSort: Sortable;
  private availableSort: Sortable;

  static addDragDrop(idSuffix: string = ''): Routings {
    var router = new Routings(idSuffix);
    router.setup();

    return router;
  }

  constructor(idSuffix: string) {
    this.idSuffix = idSuffix;
    this.selected = document.getElementById(`selected-workstations${idSuffix}`);
    this.available = document.getElementById(`available-workstations${idSuffix}`);
    this.orderId = document.getElementById(`WorkstationIds${idSuffix}`) as HTMLInputElement;
  }

  setup(): void {
    this.selectSort = Sortable.create(this.selected, {
      group: `workstations-${this.idSuffix}`,
      onAdd: (event) => {
        this.selectSort.save();
      },
      store: {
        get: (sortable) => {
          return $(this.selected).find("[data-id]").map((i, el) => el.dataset['id']).toArray();
        },
        set: (sortable) => {
          var order = $(this.selected).find("[data-id]").map((i, el) => `${i}:${el.dataset['id']}`).toArray();
          this.orderId.value = order.join(",");
        }
      }
    });

    if (this.available) {
      this.availableSort = Sortable.create(this.available, {
        group: `workstations-${this.idSuffix}`,
      });
    }
  }

  set disabled(state: boolean) {
    if (state) {
      this.selected.classList.add('disabled');
      if (this.available)
        this.available.classList.add('disabled');
    } else {
      this.selected.classList.remove('disabled');
      if (this.available)
        this.available.classList.remove('disabled');
    }

    this.selectSort.option('disabled', state);
    if (this.availableSort)
      this.availableSort.option('disabled', state);
  }
}
