// declare function require(name: string);

require("expose-loader?LocalTime!local-time");
//require("expose-loader?$!jquery");

const dt = require("datatables.net")();
const dtr = require("datatables.net-responsive")();
const dbs = require("datatables.net-bs4")();
const dbrs = require("datatables.net-responsive-bs4")();

import Cookies from 'js-cookie';
import "blueimp-file-upload";
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.sv.min";
import "clockpicker/dist/bootstrap-clockpicker.min";
import "select2";
import "sortablejs";
import "jquery-validation/dist/jquery.validate";
import "jquery-validation-unobtrusive/jquery.validate.unobtrusive";
import "qrcode-generator";
import "qrcode-generator/qrcode_UTF8";

import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/util";
