import { currencyFormat } from "./i18n";

export class Statistics {
    static init(): Statistics {
        return new Statistics();
    }

    static floatVal(i: string | number): number {
        if (typeof i === 'string')
            if (i === '')
                return 0;
            else
                return parseFloat(i.replace(/[\(\)%,]/g, ''));

        if (typeof i === 'number')
            return i || 0;
    };

    static intVal(i: string | number): number {
        if (typeof i === 'string')
            if (i === '')
                return 0;
            else
                return parseInt(i.replace(/[\(\)%,]/g, ''), 10);

        if (typeof i === 'number')
            return i || 0;
    };

    salespersonTables: DataTables.Api;
    salespersonTable: DataTables.Api;
    weeksTables: DataTables.Api;
    currentWeekTables: DataTables.Api;

    constructor() {
        this.salespersonTables = $("#salespersons table").DataTable({
            responsive: true,
            ordering: true,
            footerCallback: this.salespersonSumCallback
        });

        this.salespersonTable = $("#salesperson").DataTable({
            responsive: true,
            ordering: false,
            footerCallback: this.salespersonSumCallback
        });

        this.currentWeekTables = $("#current table").DataTable({
            responsive: true,
            ordering: true,
            footerCallback: this.salespersonSumCallback
        });

        this.weeksTables = $("#weeks").DataTable({
            responsive: true,
            ordering: true,
            order: [
                [0, 'desc']
            ],
            footerCallback: this.weeksSumCallback
        });
    }

    private weeksSumCallback(row: HTMLElement, _data: any[], _start: number, _end: number, _display: any[]): void {
        var api = (this as any).api();

        var colIndex: number;
        var processed: number;
        var total: number;
        var averagePerOrder: number;
        var averagePerWeek: number;
        var orderCount: number;

        var $row = $(row);

        $row.find("[data-order]").each((i, el) => {
            var colIndex = $row.find("td").index(el);
            orderCount = api
                .column(colIndex, { page: 'current' })
                .nodes()
                .reduce((a, b) => {
                    const html = $(b);
                    let text = '';
                    if (html.data("order") != null)
                        text = html.text();

                    return Statistics.intVal(a) + Statistics.intVal(text);
                }, 0);

            $(api.column(colIndex).footer()).html(
                `${orderCount}`
            );
        });

        $row.find("[data-total]").each((i, el) => {
            var colIndex = $row.find("td").index(el);
            total = api
                .column(colIndex, { page: 'current' })
                .nodes()
                .reduce((a, b) => {
                    const html = $(b);
                    let text = '';
                    if (html.data("total") != null)
                        text = html.data("total");

                    return Statistics.floatVal(a) + Statistics.floatVal(text);
                }, 0);

            $(api.column(colIndex).footer()).html(
                `${currencyFormat(total)}`
            );
        });

        $row.find("[data-average]").each((i, el) => {
            var colIndex = $row.find("td").index(el);
            var nodes = api
                .column(colIndex, { page: 'current' })
                .nodes();

            averagePerOrder = nodes
                .reduce((a, b) => {
                    const html = $(b);
                    let text = '';
                    if (html.data("total") != null)
                        text = html.data("total");

                    return Statistics.floatVal(a) + Statistics.floatVal(text);
                }, 0);

            averagePerWeek = nodes
                .reduce((a, b) => {
                    const html = $(b);
                    let text = '';
                    if (html.data("total") != null)
                        text = html.data("total");

                    return Statistics.floatVal(a) + Statistics.floatVal(text);
                }, 0);

            orderCount = nodes
                .reduce((a, b) => {
                    const html = $(b);
                    let text = '';
                    if (html.data("order") != null)
                        text = html.data("order");

                    return Statistics.intVal(a) + Statistics.intVal(text);
                }, 0);

            $(api.column(colIndex).footer()).find("[data-per-order]").html(
                `${currencyFormat(averagePerOrder / orderCount)}`
            );

            $(api.column(colIndex).footer()).find("[data-per-week]").html(
                `${currencyFormat(averagePerWeek / nodes.length)}`
            );
        });
    }

    private salespersonSumCallback(row: HTMLElement, _data: any[], _start: number, _end: number, _display: any[]): void {
        var api = (this as any).api();

        var colIndex: number;
        var processed: number;
        var total: number;
        var average: number;
        var orderCount: number;
        var proofCount: number;
        var proofRejected: number;
        var proofApproved: number;
        var proofNoResponse: number;
        var proofVoid: number;

        var $row = $(row);

        $row.find("[data-proof]").each((i, el) => {
            var colIndex = $row.find("td").index(el);
            var nodes = api
                .column(colIndex, { page: 'current' })
                .nodes();

            if (nodes == null)
                return;

            proofCount = nodes
                .reduce((a, b) => {
                    const html = $(b);
                    let text = '';
                    if (html.find("[data-proof-total]").length > 0)
                        text = html.find("[data-proof-total]").data("proof-total");

                    return Statistics.intVal(a) + Statistics.intVal(text);
                }, 0);

            proofApproved = nodes
                .reduce((a, b) => {
                    const html = $(b);
                    let text = '';
                    if (html.find("[data-approved]").length > 0)
                        text = html.find("[data-approved]").data("approved");

                    return Statistics.intVal(a) + Statistics.intVal(text);
                }, 0);

            proofRejected = nodes
                .reduce((a, b) => {
                    const html = $(b);
                    let text = '';
                    if (html.find("[data-rejected]").length > 0)
                        text = html.find("[data-rejected]").data("rejected");

                    return Statistics.intVal(a) + Statistics.intVal(text);
                }, 0);

            proofNoResponse = nodes
                .reduce((a, b) => {
                    const html = $(b);
                    let text = '';
                    if (html.find("[data-no-response]").length > 0)
                        text = html.find("[data-no-response]").data("no-response");

                    return Statistics.intVal(a) + Statistics.intVal(text);
                }, 0);

            proofVoid = nodes
                .reduce((a, b) => {
                    const html = $(b);
                    let text = '';
                    if (html.find("[data-void]").length > 0)
                        text = html.find("[data-void]").data("void");

                    return Statistics.intVal(a) + Statistics.intVal(text);
                }, 0);

            var footer = $(api.column(colIndex).footer());
            footer.find('[data-proof-total]').html(proofCount.toString());
            footer.find('[data-approved]').html(proofApproved.toString());
            footer.find('[data-rejected]').html(proofRejected.toString());
            footer.find('[data-void]').html(proofVoid.toString());
            footer.find('[data-no-response]').html(proofNoResponse.toString());
        });

        $row.find("[data-order]").each((i, el) => {
            var colIndex = $row.find("td").index(el);
            var nodes = api
                .column(colIndex, { page: 'current' })
                .nodes();

            if (nodes) {
                orderCount = nodes
                    .reduce((a, b) => {
                        const html = $(b);
                        let text = '';
                        if (html.data("order") != null)
                            text = html.text();

                        return Statistics.intVal(a) + Statistics.intVal(text);
                    }, 0);
            } else {
                orderCount = 0;
            }

            $(api.column(colIndex).footer()).html(
                `${orderCount}`
            );
        });

        $row.find("[data-total]").each((i, el) => {
            var colIndex = $row.find("td").index(el);
            var nodes = api
                .column(colIndex, { page: 'current' })
                .nodes();

            if (nodes) {
                total = nodes
                    .reduce((a, b) => {
                        const html = $(b);
                        let text = '';
                        if (html.data("total") != null)
                            text = html.data("total");

                        return Statistics.floatVal(a) + Statistics.floatVal(text);
                    }, 0);
            } else {
                total = 0;
            }

            $(api.column(colIndex).footer()).html(
                `${currencyFormat(total)}`
            );
        });

        $row.find("[data-average]").each((i, el) => {
            var colIndex = $row.find("td").index(el);
            var nodes = api
                .column(colIndex, { page: 'current' })
                .nodes();

            if (nodes) {
                average = nodes
                    .reduce((a, b) => {
                        const html = $(b);
                        let text = '';
                        if (html.data("average") != null)
                            text = html.data("average");

                        return Statistics.floatVal(a) + Statistics.floatVal(text);
                    }, 0);
            } else {
                nodes = ['']; // Avoid null and divide-by-zero
                average = 0;
            }

            $(api.column(colIndex).footer()).find("[data-average]").html(
                `${currencyFormat(average / nodes.length)}`
            );
        });
    }
}

