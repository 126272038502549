import * as jQuery from 'jquery';
import { orderIsDelivered, orderIsPrepared, orderIsMine, orderHasProofResponse, orderHasProof } from './filter-functions';

declare var Tryckhuset: any;
var re = new RegExp("");
var unplannedCheck = document.getElementById("unplanned") as HTMLInputElement;
var deliveredCheck = document.getElementById("not-delivered") as HTMLInputElement;
var ownCheck = document.getElementById("own-orders") as HTMLInputElement;
var preparedCheck = document.getElementById("not-configured") as HTMLInputElement;

enum ProofState {
  All = 1,
  NotProofed,
  NoResponse
}

var checkDeliveredFn = (oSettings, iDataIndex: number, val: boolean): boolean => {
  // If checkbox is disabled, show all
  if (!val)
    return true;

  return orderIsDelivered(oSettings, iDataIndex);
}

var checkOwnOrderFn = (oSettings, iDataIndex: number, checked: boolean): boolean => {
  // If checkbox is disabled, show all
  if (!checked)
    return true;

  return orderIsMine(oSettings, iDataIndex);
}

var checkPreparedFn = (oSettings, iDataIndex: number, checked: boolean): boolean => {
  // If checkbox is disabled, show all
  if (!checked)
    return true;

  return orderIsPrepared(oSettings, iDataIndex);
}

var checkProofedFn = (oSettings, iDataIndex: number, state: ProofState): boolean => {
  if (state == ProofState.All)
    return true;

  if (state == ProofState.NoResponse)
    return orderHasProof(oSettings, iDataIndex) && orderHasProofResponse(oSettings, iDataIndex, 'Null');

  if (state == ProofState.NotProofed)
    return !orderHasProof(oSettings, iDataIndex);
}

var orderIsProofed = (oSettings, iDataIndex: number): boolean => {
  return orderHasProofResponse(oSettings, iDataIndex, "True") && orderHasProof(oSettings, iDataIndex);
}

jQuery.fn.dataTable.ext.afnFiltering.push(function (oSettings, aData: string[], iDataIndex: number) {
  var filter = oSettings.nTable.dataset.filter;
  if (filter && filter.indexOf('preparation') > -1) {
    // true if order is owned by current user
    var ownerFilter = checkOwnOrderFn(oSettings, iDataIndex, ownCheck.checked);

    // true if order is delivered
    var deliveredFilter = checkDeliveredFn(oSettings, iDataIndex, deliveredCheck.checked);

    // true if order is prepared
    var preparedFilter = checkPreparedFn(oSettings, iDataIndex, preparedCheck.checked);

    var proofRadio: HTMLInputElement = document.querySelector('input[name=proof-filter]:checked');
    var proofSelection = parseInt(proofRadio.value, 10) as ProofState;
    var proofFilter = checkProofedFn(oSettings, iDataIndex, proofSelection);

    if (unplannedCheck.checked) { // Include everything
      if (!deliveredCheck.checked && !preparedCheck.checked && !ownCheck.checked) // Really everything
        return proofFilter;

      if (deliveredCheck.checked && !preparedCheck.checked)
        return !orderIsDelivered(oSettings, iDataIndex) && ownerFilter && proofFilter;

      if (!deliveredCheck.checked && preparedCheck.checked)
        return !orderIsPrepared(oSettings, iDataIndex) && ownerFilter && proofFilter;

      if (deliveredCheck.checked && preparedCheck.checked)
        return !orderIsDelivered(oSettings, iDataIndex) && ownerFilter && !orderIsPrepared(oSettings, iDataIndex) && proofFilter;

      if (!deliveredCheck.checked && !preparedCheck.checked && ownCheck.checked)
        return ownerFilter && proofFilter;

      return ((deliveredCheck.checked && !orderIsDelivered(oSettings, iDataIndex)) || !deliveredFilter) && ownerFilter && preparedFilter && proofFilter;
    }

    if (!unplannedCheck.checked && orderIsPrepared(oSettings, iDataIndex) && orderIsProofed(oSettings, iDataIndex)) {
      return false;
    }

    if (deliveredCheck.checked && !preparedCheck.checked)
      return !orderIsDelivered(oSettings, iDataIndex) && ownerFilter && proofFilter;

    if (!deliveredCheck.checked && preparedCheck.checked)
      return !orderIsPrepared(oSettings, iDataIndex) && ownerFilter && proofFilter;

    if (deliveredCheck.checked && preparedCheck.checked)
      return !orderIsDelivered(oSettings, iDataIndex) && ownerFilter && !orderIsPrepared(oSettings, iDataIndex) && proofFilter;

    return deliveredFilter && ownerFilter && preparedFilter && proofFilter;
  }

  return true;
});