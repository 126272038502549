var $ = require('jquery');
import * as DataTables from 'datatables.net';
import compact from 'lodash-es/compact';
import { getQuerystringParam, setQuerystringParam } from './querystring';
import { createEvent } from './createEvent';

declare var Tryckhuset: any;

export class OutgoingDeliveries {
  static init(ids: string = '') {
    Tryckhuset.Filtering.VisibleWorkstations = compact(ids.split(','));
    return new OutgoingDeliveries();
  }

  tables: any[] = [];

  constructor() {
    $('[data-filter=outgoing-delivery]').each((i, el) => {
      this.tables.push($(el).DataTable({
        responsive: true,
        //columnDefs: [
        //  { width: "64px", targets: 0 },
        //  { width: "60px", targets: 1 },
        //  { width: "160px", targets: 2 },
        //  { width: "110px", targets: 3 },
        //  { width: "16px", targets: 4 },
        //  { width: "42px", targets: 5 }
        //]
      }));
    });

    this.setupFilters();
    this.setupListeners();
    this.activateFilters();
  }

  private setupFilters() {
    $("#filter-workstation").on('switchChange.bootstrapSwitch', 'input.switch', (e: Event) => {
      var enabled: string[] = $("#filter-workstation input.switch:checked").map(function (i, el) {
        return el.dataset.workstationId;
      }).toArray();

      setQuerystringParam('Workstations', enabled.length == 0 ? null : enabled.join(','));
      Tryckhuset.Filtering.VisibleWorkstations = enabled;
      this.tables.map((table) => table.draw());
    });
  }

  private setupListeners() {
    $("[data-toggle=tab]").on('shown.bs.tab', (e) => {
      this.tables.map((table) => {
        table
          .columns.adjust()
          .responsive.recalc();
      });
    });

    $("[data-collapsible]")
      .on('hidden.bs.collapse', function () {
        $(this).removeClass('filter-expanded').addClass('filter-collapsed');
      })
      .on('shown.bs.collapse', function () {
        $(this).removeClass('filter-collapsed').addClass('filter-expanded');
      });

    $("body")
      .on('show.bs.tab', function (event) {
        const filter = (event.target as HTMLElement).dataset['filter'];
        if (filter) {
          setQuerystringParam('Active', filter);
        }
      });
  }

  public activateFilters(): void {
    let didActivateTab = false;
    if (getQuerystringParam('MyOrders') === '1') {
      didActivateTab = true;
      $('a[href="#tab-mine"]').tab('show');
    }

    if (getQuerystringParam('AllOrders') === '1') {
      didActivateTab = true;
      $('a[href="#tab-all"]').tab('show');
    }

    const activeTab = getQuerystringParam('Active');
    if (activeTab && activeTab != 'mine' && activeTab != 'all') {
      didActivateTab = true;
      const tab = $(`a[href='#tab-${activeTab}']`).tab('show');
    }

    if (!didActivateTab)
      $("a[data-toggle=tab]").first().tab('show');

    const changeEvent = createEvent('change');
    const workstations = getQuerystringParam('Workstations');

    if (workstations) {
      const stations = workstations.split(',');
      for (const station of stations) {
        const check = $(`[data-workstation-id=${station}]`)[0] as HTMLInputElement;
        check.checked = true;
        check.dispatchEvent(changeEvent);
      }
    }
  }
}