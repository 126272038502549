import * as $ from 'jquery';
import 'datatables.net';
import compact from 'lodash-es/compact';
import * as Cookies from 'js-cookie';

import { getQuerystringParam, setQuerystringParam } from './querystring';
import { drawCallback, sumCallback } from './dataTables/callbacks';
import { createEvent } from './createEvent';

declare var Tryckhuset: any;

export class Operator {
  static OperatorTabCookieName = 'operator-tab';

  static init(): Operator {
    return new Operator();
  }

  tables: any[] = [];

  constructor() {
    this.setupFilters();
    $('[data-filter=weekdays]').each((i, el) => {
      this.tables.push($(el).DataTable({
        footerCallback: sumCallback,
      }));
    });
    this.addListeners();
    this.activateFilters();
    Tryckhuset.App.autoRefresh();
    Tryckhuset.PdfViewer.addPreviewListeners();
  }

  private setupFilters(): void {
    $(".filter-container").on('change', 'input.switch', (e: JQueryEventObject) => {
      var enabled: string[] = $(e.delegateTarget).find("input.switch:checked").map(function (i, el) {
        return (el as HTMLInputElement).name;
      }).toArray() as any as string[];

      if (enabled.length == 0) {
        setQuerystringParam('Days', null);
        Cookies.remove(Operator.OperatorTabCookieName);
      } else {
      }
      setQuerystringParam('Days', enabled.length == 0 ? null : enabled.join(','));
      var table = $(e.target).parents('.tab-pane').find('table');
      var dataTable = table.DataTable();
      dataTable.draw();
    });
  }

  private addListeners(): void {
    $('body')
      .on('hide.bs.collapse', function (event) {
        $(event.target).parents("tr").removeClass('expanded').addClass('collapsed');
      })
      .on('show.bs.collapse', function (event) {
        $(event.target).parents("tr").addClass('expanded').removeClass('collapsed');
      })
      .on('show.bs.tab', function (event) {
        const filter = (event.target as HTMLElement).dataset['filter'];
        if (filter) {
          Cookies.set(Operator.OperatorTabCookieName, filter);
          setQuerystringParam('Active', filter);
        }
      });
  }

  private activateFilters(): void {
    const cookieTab = Cookies.get(Operator.OperatorTabCookieName);
    const queryTab = getQuerystringParam('Active');

    const activeTab = cookieTab || queryTab;
    if (activeTab) {
      const tab = $(`a[href='#tab-${activeTab}']`).tab('show');
    }

    const activeDays = getQuerystringParam('Days');
    if (activeDays) {
      $(".tab-pane.active input.switch").each((i, el) => {
        $(el).prop('checked', false);
      });

      activeDays.split(',').forEach(day => {
        $(`.tab-pane.active input[name=${day}]`).prop('checked', true);
      });

      const changeEvent = createEvent('change');
      $(".tab-pane.active input.switch").each((i, el) => {
        el.dispatchEvent(changeEvent);
      });
    }
  }
}
