import { LoadingData } from "select2";

export class Fortnox {
  static addSearch() {
    // Various activations for select2
    $(function () {
      var ajaxOptions: Select2.AjaxOptions = {
        url: "/Admin/FortnoxCustomers/Search",
        method: 'post',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            query: params.term
          };
        },
        processResults: function (data) {
          return {
            results: $.map(data, function (obj) {
              obj.id = obj.item1;
              obj.text = obj.item2;
              obj.email = obj.item3;

              return obj;
            })
          };
        },
        cache: true
      };

      // Customer company creation search with AJAX
      $(".select2-customersearch").select2({
        language: 'sv',
        theme: 'bootstrap',
        ajax: ajaxOptions,
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        minimumInputLength: 1,
        templateResult: function (customer) {
          if ((customer as LoadingData) && (customer as LoadingData).loading) return customer.text;
          return `<div class='select2-result-customer clearfix'>${customer.text} (${customer.id})</div>`;
        },
        templateSelection: function (customer) {
          return customer.text;
        }
      });
      $(".select2-customersearch").on("select2:select",
        function (e: any) {
          var idtarget = $(e.target).data("idtarget");
          if (typeof idtarget != 'undefined') {
            $(idtarget).val(e.params.data.id);
          }

          var nametarget = $(e.target).data("nametarget");
          if (typeof nametarget != 'undefined') {
            $(nametarget).val(e.params.data.text);
          }

          $("[data-src=id]").val(e.params.data.id).text(e.params.data.id);
          $("[data-src=name]").val(e.params.data.text).text(e.params.data.text);
          $("[data-src=email]").val(e.params.data.email).text(e.params.data.email);
        });
    });
  }
}
