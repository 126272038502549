var $ = require('jquery');

export class Config {
  doReload = false;
  blunder = {
    projectId: '100c6127-74bb-4ed4-8e02-102618cbef55',
    projectKey: 'F915AF76EC27581CFB3CADEA99623BE68B6B5061',
    component: 'Tryckhuset.Web.JS',
    environment: 'production',
    user: null
  };

  setupDefaults() {
    $.extend($.fn.dataTable.defaults, {
      pageLength: 50,
      ordering: false,
      language: {
        url: "/assets/datatables.sv.json"
      }
    });

    $.fn.datepicker.defaults.format = "yyyy-mm-dd";
    $.fn.datepicker.defaults.language = "sv";
    $.fn.datepicker.defaults.todayBtn = "linked";
    $.fn.datepicker.defaults.calendarWeeks = true;
    $.fn.datepicker.defaults.autoclose = true;
    $.fn.datepicker.defaults.assumeNearbyYear = true;
    $.fn.datepicker.defaults.orientation = 'auto bottom';

    $.fn.select2.defaults.set("theme", "bootstrap4");
    $.fn.select2.defaults.set("language", "sv");
  }
}
