import { Routings } from "./routings";

declare var Tryckhuset: any;

export class PreparationRows {
  static Routings: any = [];
  static Articles: any = [];
  sortable: Routings[] = [];
  state: any[] = [];

  init(): void {
    $("input[type=checkbox][name=UseRouting]").each((i, el) => this.toggleRoutingDisabled(el));
    $("select[name=RoutingId]").each((i, el) => this.toggleCreateRoute(el));
    $("input[type=checkbox][name=CreateRoutingOperations]").each((i, el) => this.toggleRouteName(el));
    $("input[type=checkbox][name=Ignore]").each((i, el: HTMLInputElement) => this.toggleIgnoreRow(el));

    var prep = this;
    $(".preparation-order-row-form")
      .on('change', "input[type=checkbox][name=UseRouting]", function () { prep.toggleRoutingDisabled(this); })
      .on('change', "select[name=RoutingId]", function () { prep.toggleCreateRoute(this); })
      .on('change', "input[type=checkbox][name=CreateRoutingOperations]", function () { prep.toggleRouteName(this); })
      .on('change', "input[type=checkbox][name=Ignore]", function () { prep.toggleIgnoreRow(this); })
      .on('click', "[data-toggle=operations-collapse]", function () {
        $(this).parents('.operations').find('.collapse').collapse('toggle');
        return false;
      });
  }

  addSortable(id: string): void {
    this.sortable[id] = Tryckhuset.Routings.addDragDrop(`-${id}`);
  }

  toggleIgnoreRow(checkbox: HTMLInputElement): void {
    const row = $(checkbox).parents('.order-row-routing');
    const operations = row.find('.operations');
    if (checkbox.checked) {
      operations.hide();
      // Deactivate everything
      this.state[row.data('id')] = [];
      row.find("input:not([name=Ignore]):not([type=hidden]):not([type=submit]), select").each((i, el: HTMLInputElement | HTMLSelectElement) => {
        this.state[row.data('id')].push(el.disabled);
        el.disabled = true;
      });
    } else {
      operations.show();
      // Restore state
      if (this.state[row.data('id')]) {
        row.find("input:not([name=Ignore]):not([type=hidden]):not([type=submit]), select").each((i, el: HTMLInputElement | HTMLSelectElement) => {
          el.disabled = this.state[row.data('id')][i];
        });
      }
    }
  }

  toggleRoutingDisabled(check): void {
    const $this = $(check);
    const rowId = $this.parents("[data-row-id]").data('row-id');
    const $form = $this.parents(".preparation-order-row-form");
    const $routing = $form.find("select[name=RoutingId]");
    const $operations = $form.find(".operations select");
    const $createRoute = $form.find("input[type=checkbox][name=CreateRouting]");
    const $createOperationsRoute = $form.find("[data-toggle='create-routing-operations']");

    if ($this.prop('checked')) {
      $routing.removeAttr('disabled');
      $operations.attr('disabled', 'disabled');
      this.sortable[rowId].disabled = true;

      // Find correct routing
      this.setRouteSelection($form, $routing.val());

      var articleId = $this.parents("[data-article-id]").data('article-id');
      if ($routing.val() !== PreparationRows.Articles[articleId]) {
        $createRoute.removeAttr('disabled').parents(".checkbox").show();
      }

      $createOperationsRoute.hide().find("input[type=checkbox]").attr('disabled', 'disabled');

      $operations.trigger('change');
    }
    else {
      $routing.attr('disabled', 'disabled');
      $createRoute.attr('disabled', 'disabled').parents(".checkbox").hide();
      $createOperationsRoute.show().find("input[type=checkbox]").removeAttr('disabled');
      $operations.removeAttr('disabled');
      this.sortable[rowId].disabled = false;
    }
  }

  toggleCreateRoute(select): void {
    const $this = $(select);
    if ($this.attr('disabled'))
      return;

    const $form = $this.parents(".preparation-order-row-form");
    const $createRoute = $form.find("input[type=checkbox][name=CreateRouting]");
    const $createOperationsRoute = $form.find("[data-toggle='create-routing-operations'] input");
    const articleId = $this.parents("tr").data('article-id');

    this.setRouteSelection($form, $form.find("select[name=RoutingId]").val());

    if (PreparationRows.Articles[articleId] != null && PreparationRows.Articles[articleId].indexOf($this.val()) > -1) {
      // Route is already configured
      $createRoute.attr('disabled', 'disabled').parents(".checkbox").hide();
    } else {
      $createRoute.removeAttr('disabled').parents(".checkbox").show();
    }
  }

  toggleRouteName(checkbox): void {
    const $this = $(checkbox);
    const $ops = $this.parents("[data-toggle='create-routing-operations']");

    if ($this.prop('checked')) {
      $ops.find("input[type=text]").removeAttr('disabled');
    } else {
      $ops.find("input[type=text]").attr('disabled', 'disabled');
    }
  }

  setRouteSelection(form, routingId): void {
    form.find("select[name=IncomingWorkstation]").val(PreparationRows.Routings[routingId].incomingWorkstation);
    form.find("select[name=OutgoingWorkstation]").val(PreparationRows.Routings[routingId].outgoingWorkstation);

    var $available = form.find('.available-workstations');
    var $selected = form.find('.selected-workstations');
    var workstations = PreparationRows.Routings[routingId].workstations.split(",");

    var sws = $selected.find('[data-id]');
    for (var i = 0; i < sws.length; i++) {
      var workstationId = sws[i].dataset['id'];

      if (workstations.indexOf(workstationId) === -1) {
        $available.append(sws);
      }
    }

    if (workstations.length > 0) {
      for (var i = 0; i < workstations.length; i++) {
        var workstationId = workstations[i];

        var aws = $available.find('[data-id=' + workstationId + ']');
        if (aws.length > 0) {
          $selected.append(aws);
        }
      }
    }
  }
}