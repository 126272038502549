export function getQuerystringParam(name: string, url: string = null): string {
  if (!url)
    url = window.location.href;

  name = name.replace(/[\[\]]/g, "\\$&");

  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  var results = regex.exec(url);

  if (!results)
    return null;

  if (!results[2])
    return '';

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function setQuerystringParam(name: string, value: string, origin: string = null): void {
  // We only want sane browsers, thank you very much
  if (!window.URLSearchParams || !window.URL)
    return;

  let url: URL;
  if (!origin)
    url = new URL(window.location.href);
  else
    url = new URL(origin);

  var search = url.searchParams;

  if (value === null)
    search.delete(name);
  else
    search.set(name, value);

  history.replaceState(null, '', `?${search.toString()}`);
}