import * as $ from 'jquery';
import 'datatables.net';
import compact from 'lodash-es/compact';
import * as Cookies from 'js-cookie';

import { getQuerystringParam, setQuerystringParam } from './querystring';
import { drawCallback, sumCallback } from './dataTables/callbacks';
import { createEvent } from './createEvent';

declare var Tryckhuset: any;

export class Deadline {
    static TabCookieName = 'deadline-tab';

    static init(ids: string[] = []): Deadline {
        return new Deadline(ids);
    }

    tables: any[] = [];

    constructor(ids: string[] = []) {
        Tryckhuset.Filtering.VisibleWorkstations = compact(ids);

        this.setupFilters();
        $('[data-filter=weekdays]').each((i, el) => {
            this.tables.push($(el).DataTable({
                footerCallback: sumCallback,
            }));
        });
        this.addListeners();
        this.activateFilters();
        Tryckhuset.App.autoRefresh();
        Tryckhuset.PdfViewer.addPreviewListeners();
    }

    private setupFilters(): void {
        $("#day-filter").on('change', 'input.switch', (e: JQueryEventObject) => {
            var enabled: string[] = $(e.delegateTarget).find("input.switch:checked").map(function (i, el) {
                return (el as HTMLInputElement).name;
            }).toArray() as any as string[];

            if (enabled.length == 0) {
                setQuerystringParam('Days', null);
            } else {
            }
            setQuerystringParam('Days', enabled.length == 0 ? null : enabled.join(','));
            var table = $(e.target).parents('.tab-pane').find('table');
            var dataTable = table.DataTable();
            dataTable.draw();
        });

        $("#workstation-filter").on('change', 'input.switch', (e: JQueryEventObject) => {
            var enabled: string[] = $(e.delegateTarget).find("input.switch:checked").map(function (i, el) {
                return (el as HTMLInputElement).name;
            }).toArray() as any as string[];
            var enabledIds: string[] = $(e.delegateTarget).find("input.switch:checked").map(function (i, el) {
                return (el as HTMLInputElement).dataset.workstationId;
            }).toArray() as any as string[];

            if (enabled.length == 0) {
                setQuerystringParam('Workstations', null);
            } else {
            }
            setQuerystringParam('Workstations', enabled.length == 0 ? null : enabled.join(','));
            Tryckhuset.Filtering.VisibleWorkstations = enabledIds;
            var table = $(e.target).parents('.tab-pane').find('table');
            var dataTable = table.DataTable();
            dataTable.draw();
        });
    }

    private addListeners(): void {
        $('body')
            .on('hide.bs.collapse', function (event) {
                $(event.target).parents("tr").removeClass('expanded').addClass('collapsed');
            })
            .on('show.bs.collapse', function (event) {
                $(event.target).parents("tr").addClass('expanded').removeClass('collapsed');
            })
            .on('show.bs.tab', function (event) {
                const filter = (event.target as HTMLElement).dataset['filter'];
                if (filter) {
                    Cookies.set(Deadline.TabCookieName, filter);
                    setQuerystringParam('Active', filter);
                }
            });
    }

    private activateFilters(): void {
        const cookieTab = Cookies.get(Deadline.TabCookieName);
        const queryTab = getQuerystringParam('Active');

        const activeTab = cookieTab || queryTab;
        if (activeTab) {
            const tab = $(`a[href='#tab-${activeTab}']`).tab('show');
        }

        const activeDays = getQuerystringParam('Days');
        if (activeDays) {
            $("#day-filter input.switch").each((i, el) => {
                $(el).prop('checked', false);
            });

            activeDays.split(',').forEach(day => {
                $(`#day-filter input[name=${day}]`).prop('checked', true);
            });

            const changeEvent = createEvent('change');
            $("#day-filter input.switch").each((i, el) => {
                el.dispatchEvent(changeEvent);
            });
        }

        const activeWorkstations = getQuerystringParam('Workstations');
        if (activeWorkstations) {
            $("#workstation-filter input.switch").each((i, el) => {
                $(el).prop('checked', false);
            });

            activeWorkstations.split(',').forEach(ws => {
                $(`#workstation-filter input[name=${ws}]`).prop('checked', true);
            });

            const changeEvent = createEvent('change');
            $("#workstation-filter input.switch").each((i, el) => {
                el.dispatchEvent(changeEvent);
            });
        }
    }
}
