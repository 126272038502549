var $ = require('jquery');
import * as DataTables from 'datatables.net';
import compact from 'lodash-es/compact';

import { getQuerystringParam, setQuerystringParam } from './querystring';
import { createEvent } from './createEvent';

declare var Tryckhuset: any;

export class IncomingDeliveries {
  static init(ids: string = '') {
    Tryckhuset.Filtering.VisibleWorkstations = compact(ids.split(','));
    return new IncomingDeliveries();
  }

  table: any;

  constructor() {
    this.table = $("#incoming-deliveries").DataTable();
    this.setupFilters();
    this.setupListeners();
    this.activateFilters();
  }

  private setupFilters() {
    $("#filter-workstation").on('change', 'input.switch', (e: Event) => {
      var enabled: string[] = $("#filter-workstation input.switch:checked").map(function (i, el) {
        return el.dataset.workstationId;
      }).toArray();

      setQuerystringParam('Workstations', enabled.length == 0 ? null : enabled.join(','));
      Tryckhuset.Filtering.VisibleWorkstations = enabled;
      this.table.draw();
    });

    $("#late-deliveries").on('switchChange.bootstrapSwitch', (e: Event) => {
      const checked = (e.currentTarget as HTMLInputElement).checked;
      setQuerystringParam('LateDeliveries', checked ? '1' : null);
      this.table.draw();
    });

    $("#own-orders").on('switchChange.bootstrapSwitch', (e: Event) => {
      const checked = (e.currentTarget as HTMLInputElement).checked;
      setQuerystringParam('MyOrders', checked ? '1' : null);
      this.table.column(4).visible(!checked);
      this.table.draw();
    });
  }

  private setupListeners() {
    $("[data-collapsible]")
      .on('hidden.bs.collapse', function () {
        $(this).removeClass('filter-expanded').addClass('filter-collapsed');
      })
      .on('shown.bs.collapse', function () {
        $(this).removeClass('filter-collapsed').addClass('filter-expanded');
      })
      ;
  }

  public activateFilters(): void {
    var myOrders = getQuerystringParam('MyOrders');
    var lateDeliveries = getQuerystringParam('LateDeliveries');
    var workstations = getQuerystringParam('Workstations');

    var changeEvent = createEvent('change');

    if (myOrders) {
      var check = document.getElementById('own-orders') as HTMLInputElement;
      if (check) {
        check.checked = myOrders === '1';
        check.dispatchEvent(changeEvent);
      }
    }

    if (lateDeliveries) {
      var check = document.getElementById('late-deliveries') as HTMLInputElement;
      if (check) {
        check.checked = lateDeliveries === '1';
        check.dispatchEvent(changeEvent);
      }
    }

    if (workstations) {
      const stations = workstations.split(',');
      for (const station of stations) {
        const check = $(`[data-workstation-id=${station}]`)[0] as HTMLInputElement;
        check.checked = true;
        check.dispatchEvent(changeEvent);
      }
    }
  }
}
