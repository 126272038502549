import 'blueimp-file-upload/css/jquery.fileupload.css';
import "./styles/bootstrap.scss";
import "select2/dist/css/select2.css";
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";
import "font-awesome/css/font-awesome.css";
import "clockpicker/dist/bootstrap-clockpicker.min.css";

import "./styles/switch.scss";

import "./site.scss";
import "./styles/notifications.scss";
import "./styles/labels.scss";
import "./styles/orderhistory.scss";
import "./styles/proof.scss";
import "./styles/deadline.scss";
import "./styles/statistics.scss";
import "./styles/spinner.scss";
import "./styles/incoming-deliveries.scss";

import "./styles/datatables-bootstrap.css";
import "./styles/vendor/datepicker.scss";

import "../ordersystem/style.scss";
import img from "./assets/logo_black.png";
