var Handlebars = require('D:/Tryckhuset/Produktionssystem/Tryckhuset.Produktionssystem.Web/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <li class=\"address-suggestion\" data-street=\""
    + alias4(((helper = (helper = helpers.street || (depth0 != null ? depth0.street : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"street","hash":{},"data":data}) : helper)))
    + "\" data-street-number=\""
    + alias4(((helper = (helper = helpers.street_number || (depth0 != null ? depth0.street_number : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"street_number","hash":{},"data":data}) : helper)))
    + "\" data-postalcode=\""
    + alias4(((helper = (helper = helpers.postalcode || (depth0 != null ? depth0.postalcode : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"postalcode","hash":{},"data":data}) : helper)))
    + "\" data-locality=\""
    + alias4(((helper = (helper = helpers.locality || (depth0 != null ? depth0.locality : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locality","hash":{},"data":data}) : helper)))
    + "\" data-letter=\""
    + alias4(((helper = (helper = helpers.letter || (depth0 != null ? depth0.letter : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"letter","hash":{},"data":data}) : helper)))
    + "\">\r\n        "
    + alias4(((helper = (helper = helpers.street || (depth0 != null ? depth0.street : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"street","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.street_number || (depth0 != null ? depth0.street_number : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"street_number","hash":{},"data":data}) : helper)))
    + ", "
    + alias4(((helper = (helper = helpers.postalcode || (depth0 != null ? depth0.postalcode : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"postalcode","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.locality || (depth0 != null ? depth0.locality : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locality","hash":{},"data":data}) : helper)))
    + "\r\n    </li>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<ul>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.suggestions : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\r\n";
},"useData":true});