import * as $ from 'jquery';
import 'datatables.net';
import compact from 'lodash-es/compact';
import * as Cookies from 'js-cookie';

import { getQuerystringParam, setQuerystringParam } from './querystring';
import { drawCallback, sumCallback } from './dataTables/callbacks';
import { createEvent } from './createEvent';

declare var Tryckhuset: any;

export class OperatorSummary {
  static SummaryTabCookieName = 'operator-summary-tab';

  static init(ids: string = ''): OperatorSummary {
    Tryckhuset.Filtering.VisibleWorkstations = compact(ids.split(','));
    return new OperatorSummary();
  }

  tables: any[] = [];

  constructor() {
    this.setupFilters();
    $('[data-filter=operators-summary]').each((i, el) => {
      this.tables.push($(el).DataTable({
        responsive: true,
        footerCallback: sumCallback,
        drawCallback: drawCallback,
        columnDefs: [
          { width: "64px", targets: 0 },
          { width: "60px", targets: 1 },
          { width: "160px", targets: 2 },
          { width: "110px", targets: 3 },
          { width: "16px", targets: 4 },
          { width: "42px", targets: 5 },
          { width: "80px", targets: 7 }
        ]
      }));
    });
    this.addListeners();
    this.activateFilters();
    Tryckhuset.PdfViewer.addPreviewListeners();
  }

  private setupFilters(): void {
    $(".filter-workstation").on('change', 'input.switch', (e: Event) => {
      var enabled: string[] = $(e.target).parents(".filter-workstation").find("input.switch:checked").map(function (i, el) {
        return (el as HTMLInputElement).dataset.workstationId;
      }).toArray() as any as string[]; // Eww

      setQuerystringParam('Workstations', enabled.length == 0 ? null : enabled.join(','));
      Tryckhuset.Filtering.VisibleWorkstations = enabled;
      this.tables.map((table) => table.draw());
    });

    $("[data-toggle=tab]").on('shown.bs.tab', (e) => {
      this.tables.map((table) => {
        table
          .columns.adjust()
          .responsive.recalc();
      });
    });
  }

  private addListeners(): void {
    $('body')
      .on('hide.bs.collapse', function (event) {
        $(event.target).parents("tr").removeClass('expanded').addClass('collapsed');
      })
      .on('show.bs.collapse', function (event) {
        $(event.target).parents("tr").addClass('expanded').removeClass('collapsed');
      })
      .on('show.bs.tab', function (event) {
        const filter = (event.target as HTMLElement).dataset['filter'];
        if (filter) {
          setQuerystringParam('Active', filter);
          Cookies.set(OperatorSummary.SummaryTabCookieName, filter);
        }
      });
  }

  private activateFilters(): void {
    let didChangeTab = false;
    if (getQuerystringParam('MyOrders') === '1') {
      $('a[href="#tab-mine"]').tab('show');
      didChangeTab = true;
    }

    if (getQuerystringParam('AllOrders') === '1') {
      $('a[href="#tab-all"]').tab('show');
      didChangeTab = true;
    }

    if (getQuerystringParam('NotPrinted') === '1') {
      $('a[href="#tab-not-printed"]').tab('show');
      didChangeTab = true;
    }

    const cookieTab = Cookies.get(OperatorSummary.SummaryTabCookieName);
    const queryTab = getQuerystringParam('Active');

    const activeTab = cookieTab || queryTab;
    if (activeTab && (!didChangeTab && activeTab != 'mine' && activeTab != 'all' && activeTab != 'not-printed')) {
      const tab = $(`a[href='#tab-${activeTab}']`).tab('show');
    }

    const changeEvent = createEvent('change');
    const workstations = getQuerystringParam('Workstations');
    if (workstations) {
      const stations = workstations.split(',');
      for (const station of stations) {
        const check = $(`[data-workstation-id=${station}]`)[0] as HTMLInputElement;
        check.checked = true;
        check.dispatchEvent(changeEvent);
      }
    }
  }
}
