const $: JQueryStatic = require('jquery');
import compact from 'lodash-es/compact';
import { getQuerystringParam, setQuerystringParam } from './querystring';
import { createEvent } from './createEvent';

declare var Tryckhuset: any;

export class Preparation {
  static init() {
    return new Preparation();
  }

  table: any;

  constructor() {
    this.setupFilters();
    this.setupListeners();
    this.table = $("#preparation-table").DataTable();
    this.activateFilters();
    Tryckhuset.PdfViewer.addPreviewListeners();
  }

  private setupFilters() {
    $("#filters-container")
      .on('change', (e: Event) => {
        var ownOrders: HTMLInputElement = document.getElementById("own-orders") as HTMLInputElement;
        const target = (e.target as HTMLInputElement);
        if (target) {
          const filter = target.dataset.filter;
          if (filter)
            setQuerystringParam(filter, target.checked ? '1' : null);
          else
            setQuerystringParam('Proof', target.value);
        }

        if (ownOrders) {
          this.table.column(5).visible(!ownOrders.checked);
        }

        this.table.draw();
      });
  }

  private setupListeners() {
    $("[data-collapsible]")
      .on('hidden.bs.collapse', function () {
        $(this).removeClass('filter-expanded').addClass('filter-collapsed');
      })
      .on('shown.bs.collapse', function () {
        $(this).removeClass('filter-collapsed').addClass('filter-expanded');
      })
      ;
  }

  public activateFilters(): void {
    this.activateFilter('MyOrders', 'own-orders');
    this.activateFilter('Routing', 'not-configured');
    this.activateFilter('Delivered', 'not-delivered');
    this.activateFilter('Unplanned', 'unplanned');

    var query = getQuerystringParam('Proof');
    if (query) {
      var changeEvent = createEvent('change');
      var proofRadio: HTMLInputElement = document.getElementById(`proof-${query}`) as HTMLInputElement;

      if (proofRadio) {
        proofRadio.checked = true;
        proofRadio.dispatchEvent(changeEvent);
      }
    }
  }

  private activateFilter(querystring: string, checkboxId: string): void {
    var query = getQuerystringParam(querystring)

    if (query) {
      var changeEvent = createEvent('change');
      var check = document.getElementById(checkboxId) as HTMLInputElement;

      if (check) {
        check.checked = query === '1';
        check.dispatchEvent(changeEvent);
      }
    }
  }
}
