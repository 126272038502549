const $: JQueryStatic = require('jquery');
import { dateFormatter } from "./i18n";

export class Notifications {
  static init() {
    return new Notifications();
  }

  constructor() {
    $("form[data-notification-dropdown]").on('submit',
      (e) => {
        return this.submitHandler(e, () => {
          const notification = $(e.currentTarget).parents(".notification");
          notification.slideUp(() => notification.remove());
        });
      });

    $("form[data-notification-table]").on('submit',
      (e) => {
        return this.submitHandler(e, () => {
          const form = $(e.currentTarget);
          const row = form.parents("tr");

          row.removeClass("table-info");
          row.find("td[data-read]").html(dateFormatter.format(new Date()));
          form.remove();
        });
      });
  }

  private submitHandler(e: JQuery.SubmitEvent, successCallback: () => void): boolean {
    e.preventDefault();

    const form = $(e.currentTarget);
    const url = form.attr("action");

    const button = form.find("button[type='submit']");
    button.prop("disabled", true);
    button.html("<i class='fa fa-spinner fa-spin'></i>");

    $.post({
      url: url,
      data: form.serialize(),
    }).done(() => {
      successCallback();
    }).fail(() => {
      button.html("<i class='fa text-danger fa-thumbs-o-down'></i> Fel");
    }).always(() => {
      button.prop("disabled", false);
    });

    e.stopImmediatePropagation();
    return false;
  }
}
